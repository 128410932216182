.Website {
  width: 100vw;
  position: absolute;
  left: 0px;
  top: 0px;
}

.Website .Website-content {
  font-family: "Montserrat", sans-serif;
  position: relative;
}

/* Mobile Introduction Section ------------------------------------------*/

.Website .Website-content .introPage {
  width: 100vw;
}

.Website .Website-content .titlePage {
  background: rgb(3,13,38);
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
}

.Website .Website-content .titlePageText {
  color: white;
  position: absolute;
  top: 25vh;
  width: 80%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
}

.Website .Website-content .titlePage h1 {
  font-family: "Anton", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.Website .Website-content .titlePage h2 {
  font-family: "Anton", sans-serif;
  font-size: 32px;
  font-weight: 400;
}

.Website .Website-content .titlePage h3 {
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 400;
  color: white;
}


.Website .titlePage .intro-call-to-action {
  display: flex;
  flex-wrap: wrap;
}

.Website .titlePage .intro-call-to-action .joinButtonSection {
  width: 100%;
  height: 50px;
  border: 1px solid white;
  color: white;
}

.Website .titlePage .intro-call-to-action .joinButtonSection:hover {
  background-color: white;
}

.Website .titlePage .intro-call-to-action .joinButtonSection a {
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website .titlePage .intro-call-to-action .joinButtonSection a:hover {
  color: rgb(3,13,38);
}

.Website .titlePage .intro-call-to-action .socialMediaSection {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.Website .titlePage .intro-call-to-action .socialMediaSection ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

.Website .titlePage .intro-call-to-action .socialMediaSection ul li {
  width: 30px;
  height: 30px;
  margin: 0px 10px;
  background-color: rgb(237, 23, 117);
  border-radius: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website .titlePage .intro-call-to-action .socialMediaSection ul li a {
  color: white;
}

.Website .titlePage .intro-call-to-action .socialMediaSection ul li:hover {
  background-color: rgb(99, 202, 221);
}


/* Mobile Concept page -----------------------------------*/

.Website .Website-content .descriptions {
  min-height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.Website .Website-content .descriptions .textColumn {
  display: flex;
  align-items: center;
}

.Website .Website-content .descriptions .textArea {
  width: 80%;
  margin-left: 10%;
}

.Website .Website-content .descriptions h2 {
  font-size: 32px;
  color: rgb(3,13,38);
}

.Website .Website-content .descriptions p {
  font-size: 16px;
  color: rgb(3,13,38);
}

.Website .Website-content .descriptions .imageColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Website .Website-content .descriptions .imageColumn img {
  width: 80%;
  margin-bottom: 20px;
}


/* Mobile Pricing Section -------------------------------*/
.Website .Website-content .pricing {
  width: 100vw;
  margin: 0;
  padding: 0;
}

.Website .Website-content section.pricing {
  width: 100%;
  height: 100vh;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 140px 0;
}

.Website .Website-content .pricing h5 {
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
}

.Website .Website-content section.pricing .row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Website .Website-content section.pricing .row .PriceCard {
  width: 90%;
  margin-left: 10%;
  margin-top: 20px;
}

/* Desktop -------------------------------------------------------------------------------*/
@media all and (min-width: 1000px) {
  .Website .Website-content .textField {
    margin: 10% 22%;
  }
  .Website .Website-content .titlePage {
    background: url(../assets/LandingPageBackground2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: left;
  }

  .Website .Website-content .titlePageText {
    background-color: rgba(10, 10, 10, 0);
    color: white;
    top: 20vh;
    width: 50%;
  }

  .Website .Website-content .titlePage h1 {
    font-family: "Anton", sans-serif;
    font-size: 24px;
  }

  .Website .Website-content .titlePage h2 {
    font-family: "Anton", sans-serif;
    font-size: 50px;
  }

  .Website .Website-content .titlePage h3 {
    font-size: 1vw;
    text-shadow: 0px 0px 0px #000000;
  }

  .Website .titlePage .intro-call-to-action .joinButtonSection {
    width: 50%;
  }

  .Website .titlePage .intro-call-to-action .socialMediaSection {
    width: 50%;
    height: 50px;
    margin-top: 10px;
  }

  .Website .Website-content .whitespace {
    height: 20vh;
    background-color: white;
  }

  .Website .Website-content .descriptions .textColumn.reverse {
    order: 1;
  }
  .Website .Website-content .descriptions .imageColumn.reverse {
    order: 2;
  }

  .Website .Website-content .login {
    border: solid 1px white;
    border-radius: 30px;
  }

  .Website .intro-call-to-action {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .Website .intro-call-to-action .joinButtonSection {
    width: 50%;
  }

  .Website .intro-call-to-action .socialMediaSection {
    width: 50%;
  }

  .Website .intro-call-to-action .socialMediaSection ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
  }

  .Website .intro-call-to-action .socialMediaSection ul li {
    width: 30px;
    height: 30px;
    margin: 0px 10px;
    background-color: rgb(237, 23, 117);
    border-radius: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Website .intro-call-to-action .socialMediaSection ul li:hover {
    background-color: rgb(99, 202, 221);
  }

  .Website .Website-content a button.introButton:hover {
    background-color: white !important;
    color: black;
  }

  .Website .Website-content section.pricing .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .Website .Website-content section.pricing .row .PriceCard {
    width: 30%;
    margin-left: 0;
  }

  .Website .Website-content .pricing h5 {
    margin-bottom: 30px;
    font-size: 28px;
    text-align: left;
  }
}

/* Large Screen -------------------------------------------------------------------------------*/
@media all and (min-width: 1500px) {
  .Website .Website-content .titlePage {
    min-height: 900px;
  }

  .Website .Website-content .titlePageText {
    background-color: rgba(10, 10, 10, 0);
    color: white;
    top: 30vh;
    width: 50%;
  }

  .Website .Website-content .titlePage h1 {
    font-family: "Anton", sans-serif;
    font-size: 36px;
  }

  .Website .Website-content .titlePage h2 {
    font-family: "Anton", sans-serif;
    font-size: 80px;
  }

  .Website .Website-content .titlePage h3 {
    font-size: 1vw;
    text-shadow: 0px 0px 0px #000000;
  }
}
