.Navbar .Navbar-avatar {
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  overflow: hidden;
}
.Navbar .Navbar-avatar img {
  width: 100%;
  height: 100%;
}

.Navbar-profileMenu {
  width: 200px;
}
