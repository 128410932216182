.Login {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
}

.Login .Login-background {
  background: url(../assets/LoginBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.Login .Login-logo {
  width: 150px;
}
