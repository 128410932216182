.SignUp {
  width: 100vw;
}

.SignUp-avatarRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.SignUp-avatarRow .Signup-logo {
  width: 50%;
}

.SignUp-avatarRow p {
  max-width: 100%;
  text-align: center;
  position: relative;
  bottom: 20px;
  font-size: 12px;
}
.SignUp-avatarRow-avatar {
  width: 300px;
}

.custom-file-input {
  color: transparent;
  margin: 0 auto;
  margin-top: 20px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "";
  display: flex;
  position: relative;
  left: 110px;
  background: url("../assets/SVG/profile.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}

.custom-file-input:hover::before {
  background: url("../assets/SVG/profile.svg");
}

.custom-file-input:active {
  outline: 0;
}

body {
  padding: 20px;
}
