.Calendar {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Calendar-content-page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Calendar-content-page-text{
  width: 100%;
  display: flex;
  justify-content: center;
}

.Calendar-content-page-text p{
  text-align: center;
}

