.Favorites {
  padding: 100px 0px;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0px;
}

.Favorites-content {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  width: 95%;
  margin: 0 auto;
}
