.PriceCard .card {
  position: relative;
  width: 100%;
  height: auto;
  background: linear-gradient(-45deg, #e5e5e5, #f2f2f2);
  border-radius: 15px;
  border: none;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 40px 20px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.PriceCard .card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.PriceCard .card {
  background: linear-gradient(-45deg, #e5e5e5, #f2f2f2);
}

.PriceCard h5 {
  position: absolute;
  top: 0;
  left: 5%;
  font-size: 32px;
  color: #4a2f2e;
  margin-bottom: 10px;
}

.PriceCard .card::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
  -webkit-transform: skewY(-5deg) scale(1.5);
  transform: skewY(-5deg) scale(1.5);
}

.PriceCard .title .fa {
  color: #4a2f2e;
  font-size: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.PriceCard .title h2 {
  position: relative;
  margin: 20px 0 0;
  padding: 0;
  color: #4a2f2e;
  font-size: 24px;
  z-index: 2;
}

.PriceCard .price,
.PriceCard .option {
  position: relative;
  z-index: 2;
}

.PriceCard .price h4 {
  margin: 0;
  padding: 20px 0;
  color: #4a2f2e;
  font-size: 50px;
}

.PriceCard .price .smallMonth {
  font-size: 14px;
}

.PriceCard .price sup {
  font-size: 30px;
}

.PriceCard .option ul {
  margin: 0;
  padding: 0;
}
.PriceCard .option ul li {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  color: #4a2f2e;
  font-size: 12px;
}
.PriceCard .card a {
  position: relative;
  z-index: 2;
  background: #fff;
  color: #4a2f2e;
  width: 150px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  display: block;
  text-align: center;
  margin: 20px auto 0;
  font-size: 16px;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.PriceCard .card a:hover {
  text-decoration: none;
}

.PriceCard .subscribeSection {
  margin-top: 30px;
  z-index: 2;
}

.PriceCard .subscribedSection p {
  font-size: 12px;
}

.PriceCard .subscribedSection {
  margin-top: 30px;
  z-index: 2;
}

.PriceCard .subscribeSection p {
  font-size: 12px;
}

.PriceCard .subscribeSection button {
  background-color: rgb(74, 47, 46);
  border-color: #945252;
}

.PriceCard .form-control:focus {
  border-color: rgb(74, 47, 46);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(74, 47, 46, 0.6);
}

.PriceCard .rocket {
  margin-top: 30px;
  font-size: 60px;
  color: rgb(74, 47, 46);
}

.PriceCard .form-inline {
  width: 100%;
  display: flex;
  justify-content: right;
}

.submitEmailButton {
  margin-top: 10px;
}

/* Desktop -------------------------------------------------------------------------------*/
@media all and (min-width: 1000px) {
  .PriceCard h5 {
    position: static;
    font-size: 32px;
    color: #4a2f2e;
    margin-bottom: 50px;
  }
}
