.Search {
  max-width: 100vw;
  min-height: 100vh;
}

.Search-content {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50px;
}

.Search-content-searchbar {
  width: 90%;
  margin: 5% auto;
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.Search-content-searchbar-input {
  width: 80%;
}

